/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-21 18:02:07
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-21 18:05:35
 */
import Account from './account.vue'
export default Account
