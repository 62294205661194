/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-21 18:02:21
 * @LastEditors: Arvin
 * @LastEditTime: 2021-01-14 16:47:22
 */
import { createNamespacedHelpers } from 'vuex'
import { numberFormat } from '@/plugins/utils'
import IconTitle from '../../Overview/components/common/iconTitle'

const { mapGetters, mapActions } = createNamespacedHelpers('App/Finance')

export default {
  name: 'Account',
  components: {
    IconTitle
  },
  computed: {
    ...mapGetters([
      'accountInfo'
    ])
  },
  methods: {

    ...mapActions([
      'showMealBuyDialog'
    ]),

    /**
     * 数据格式化
     */
    numberFormat,

    /**
     * 去升级
     */
    goToUpgrade () {
      const {
        name,
        is_new: isNew,
        can_expand: canExpand = false
      } = this.accountInfo

      this.showMealBuyDialog({
        name,
        is_new: isNew,
        can_expand: canExpand,
        combo_buy_type: isNew ? 3 : 1
      })
    }
  }
}
